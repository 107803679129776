import React from "react";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";

import "animate.css/animate.min.css";

import Title from "@components/Title";

import FEATURES from "@constants/features";

import FeatureTitles from "@enums/FeatureTitles";

import IconGrowth from "@assets/images/icon-two.inline.svg";
import IconFunding from "@assets/images/icon-four.inline.svg";
import IconApplications from "@assets/images/icon-three.inline.svg";

export default () => {
  /**
   * Fetch corresponding icon
   *
   * @param {FeatureTitles} title
   * @return {JSX.Element}
   */
  function getIcon(title: FeatureTitles): JSX.Element {
    switch (title) {
      case FeatureTitles.applications:
        return <IconApplications />;

      case FeatureTitles.funding:
        return <IconFunding />;

      case FeatureTitles.growth:
        return <IconGrowth />;

      default:
        return <></>;
    }
  }

  return (
    <StyledContainer className="pt-18 pb-18">
      {FEATURES.map(({ title, copy }, key: number) => (
        <StyledFeature key={key} className="text-center">
          <ScrollAnimation
            animateOnce={true}
            animateIn="fadeIn"
            delay={
              typeof window !== "undefined" && window.innerWidth < 1300
                ? 0
                : key * 250
            }
          >
            <StyledIcon className="mb-6">{getIcon(title)}</StyledIcon>
            <Title as="h2">{title}</Title>
            {copy.map((paragraph: string, key: number) => (
              <p key={key} className="mt-3">
                {paragraph}
              </p>
            ))}
          </ScrollAnimation>
        </StyledFeature>
      ))}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: grid;
  grid-gap: var(--grid-gap);
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    grid-template-columns: 1fr;
  }
`;

const StyledFeature = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
`;

const StyledIcon = styled.div`
  --size: 80px;
  margin: 0 auto;
  overflow: hidden;
  width: var(--size);
  height: var(--size);

  > svg {
    display: flex;
    position: relative;
    border-radius: 50%;
    justify-content: center;
    background: var(--icon-background);
  }
`;
