import React from "react";
import { Helmet } from "react-helmet";

import Welcome from "@components/Welcome";
import Contact from "@components/Contact";
import Features from "@components/Features";
import Management from "@components/Management";

export default () => (
  <div>
    <Helmet title="BUILD AND REGISTER YOUR WEB 3.0 IDENTITY" />
    <Welcome />
    <Features />
    <Management />
    <Contact />
  </div>
);
