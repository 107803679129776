import React from "react";
import { kebabCase } from "lodash";
import styled from "styled-components";

import Image from "@components/Image";
import Title from "@components/Title";

import MANAGEMENT from "@constants/management";

import backgroundImage from "@assets/images/backgrounds/management.svg";

export default () => (
  <StyledContainer className="pt-18 pb-14 text-center">
    <Title as="h2" className="pt-4 pb-1">
      Our management team
    </Title>
    <p className="pb-2">
      Introducing the team who make all these things possible
    </p>

    <StyledPeople className="pt-12">
      {MANAGEMENT.map(({ name, position }, key: number) => (
        <StyledPerson key={key}>
          <StyledImageWrapper>
            <StyledImage
              alt={name}
              /**
               * Locates images from asset directory
               * using name e.g alex-whinfield.jpg
               */
              filename={`${kebabCase(name)}.jpg`}
            />
          </StyledImageWrapper>
          <Title as="h4" className="pt-6 pb-1">
            {name}
          </Title>
          <p>{position}</p>
        </StyledPerson>
      ))}
    </StyledPeople>
  </StyledContainer>
);

const StyledImage = styled(Image)`
  filter: grayscale(100%);
`;

const StyledContainer = styled.div`
  position: relative;

  &::before {
    content: "";
    top: 4%;
    right: -6%;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${backgroundImage});
  }
`;

const StyledPeople = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 800px;
  margin-top: 3rem;
  grid-gap: var(--grid-gap);
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
`;

const StyledPerson = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    &:nth-child(2) {
      margin-top: 2rem;
    }

    &:nth-child(3) {
      margin-top: 4rem;
    }
  }
`;

const StyledImageWrapper = styled.div`
  &&& {
    .gatsby-image-wrapper {
      --size: 100%;
      width: var(--size);
      height: var(--size);
      border-radius: var(--border-radius);
      box-shadow: var(--dark-box-shadow-block);

      img {
        object-position: center top !important;
      }
    }
  }
`;
