import React from "react";
import * as Yup from "yup";
import styled from "styled-components";
import {
  Form,
  Field,
  Formik,
  FieldProps,
  FormikProps,
  FormikActions,
} from "formik";

import Title from "@components/Title";
import Button from "@components/Button";
import CardDark from "@components/CardDark";

import axios from "@axios";

import useSiteData from "@hooks/useSiteData";

import linkedInSVG from "@assets/images/linkedin.svg";
import backgroundImage from "@assets/images/backgrounds/contact.svg";

interface FormValues {
  name: string;
  email: string;
  phone: string;
  message: string;
}

export default () => {
  const {
    company: {
      name,
      owner,
      address,
      phone: { link, display },
      social: { linkedIn },
      emails: { contact },
    },
  } = useSiteData();

  const requiredText = "This field is required";

  return (
    <StyledContainer id="contact" className="pt-18 pb-18">
      <StyledInner className="pt-12 pb-12">
        <CardDark>
          <StyledGrid className="text-left">
            <div>
              <Title as="h3" className="pb-4">
                Contact Us
              </Title>

              <div className="pb-4">
                {name} Ltd.
                <br />
                {address.split(",").map((element: string, key: number) => (
                  <div key={key}>
                    {element}
                    <br />
                  </div>
                ))}
              </div>

              <a className="pb-1 display-block" href={`tel:${link}`}>
                {display}
              </a>

              <a className="pb-4 display-block" href={`mailto:${contact}`}>
                {contact}
              </a>

              <StyledLinkedIn href={linkedIn} target="_blank">
                <img
                  className="mr-3"
                  src={linkedInSVG}
                  alt={`${owner} on LinkedIn`}
                />
                LinkedIn
              </StyledLinkedIn>
            </div>

            <Formik
              initialValues={{
                name: "",
                email: "",
                phone: "",
                message: "",
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required(requiredText),
                phone: Yup.string().required(requiredText),
                message: Yup.string().required(requiredText),
                email: Yup.string()
                  .email("Please provide a valid email address")
                  .required(requiredText),
              })}
              onSubmit={(
                values: FormValues,
                actions: FormikActions<FormValues>
              ) => {
                actions.setSubmitting(true);

                axios
                  .post("send", {
                    key: "intech",
                    subject: "EdgeLabs: Contact Enquiry",
                    ...values,
                  })
                  .finally(() => {
                    actions.resetForm();
                    actions.setStatus(true);
                    actions.setSubmitting(false);
                  });
              }}
              render={(formik: FormikProps<FormValues>) => (
                <Form>
                  <Field
                    name="name"
                    render={({ field, form }: FieldProps<FormValues>) => (
                      <div className="pb-4">
                        <input type="text" {...field} placeholder="Name" />
                        {form.errors.name && (
                          <StyledError className="pt-2">
                            {form.errors.name}
                          </StyledError>
                        )}
                      </div>
                    )}
                  />
                  <Field
                    name="email"
                    render={({ field, form }: FieldProps<FormValues>) => (
                      <div className="pb-4">
                        <input type="email" {...field} placeholder="Email" />
                        {form.errors.email && (
                          <StyledError className="pt-2">
                            {form.errors.email}
                          </StyledError>
                        )}
                      </div>
                    )}
                  />
                  <Field
                    name="phone"
                    render={({ field, form }: FieldProps<FormValues>) => (
                      <div className="pb-4">
                        <input type="tel" {...field} placeholder="Phone" />
                        {form.errors.phone && (
                          <StyledError className="pt-2">
                            {form.errors.phone}
                          </StyledError>
                        )}
                      </div>
                    )}
                  />
                  <Field
                    name="message"
                    render={({ field, form }: FieldProps<FormValues>) => (
                      <div className="pb-4">
                        <textarea {...field} placeholder="Message" />
                        {form.errors.message && (
                          <StyledError className="pt-1 pb-2">
                            {form.errors.message}
                          </StyledError>
                        )}
                      </div>
                    )}
                  />
                  {formik.status === true ? (
                    <Button type="reset" disabled>
                      Thank you for your enquiry
                    </Button>
                  ) : (
                    <Button type="submit" disabled={formik.isSubmitting}>
                      Send Message
                    </Button>
                  )}
                </Form>
              )}
            />
          </StyledGrid>
        </CardDark>
      </StyledInner>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    &&& {
      padding: 0;
    }
  }

  &::before {
    content: "";
    top: -5%;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${backgroundImage});

    @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
      top: 0;
      background-position: top center;
    }
  }
`;

const StyledInner = styled.div`
  width: 100%;
  max-width: 980px;
`;

const StyledGrid = styled.div`
  display: grid;
  min-height: 350px;
  grid-gap: var(--grid-gap);
  grid-template-columns: 250px 1fr;

  p {
    color: var(--text-color-1);
  }

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    grid-template-columns: 1fr;
  }
`;

const StyledError = styled.div`
  font-size: 0.9rem;
  position: relative;
  color: var(--brand-color-primary);

  &:before {
    content: "*";
    padding-right: 0.4rem;
  }
`;

const StyledLinkedIn = styled.a`
  display: flex;
  cursor: pointer;
  color: var(--text-color-1);

  &:hover {
    color: var(--text-color-2);
  }

  > img {
    width: 1.2rem;
  }
`;
