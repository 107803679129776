import React from "react";
import styled from "styled-components";

import Title from "@components/Title";

export default () => (
  <div className="pb-18 text-center">
    <Title as="h1" className="pt-16 pb-1">
      EDGE LABS ARE BUILDING THE FUTURE OF THE INTERNET
    </Title>
    <StyledSloganContainer>
      <Title as="h2">
        <StyledSlogan>
          Through the use of Bleeding Edge technologies in Web 3.0, NFTs and the
          Metaverse we are a group of innovators helping to bring forth the
          future today.
        </StyledSlogan>
      </Title>
    </StyledSloganContainer>
  </div>
);

const StyledSloganContainer = styled.div`
  > h2 {
    text-transform: none;
    color: var(--text-color-2);
  }
`;

const StyledSlogan = styled.div`
  margin: 0 auto;
  max-width: 780px;
`;
