import FeatureTitles from "@enums/FeatureTitles";
import FeatureInterface from "@interfaces/FeatureInterface";

const FEATURES: FeatureInterface[] = [
  {
    title: FeatureTitles.applications,
    copy: [
      "Launch your own NFT project. Our NFT experts work with you to define your NFT offering, helping you build tangible value and long term benefits into your NFTs",
    ],
  },
  {
    title: FeatureTitles.growth,
    copy: [
      "Ensure the success of your NFT project by utilising our unique NFT marketing technologies",
    ],
  },
  {
    title: FeatureTitles.funding,
    copy: [
      "Build a truly unique and individualised Web 3.0 experience for your customers utilising immutable NFT verification and enabling your customer participation.",
    ],
  },
];

export default FEATURES;
