import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

export default ({ children }: Props) => (
  <StyledContainer className="pt-8 pr-10 pb-8 pl-10">
    {children}
  </StyledContainer>
);

const StyledContainer = styled.div`
  z-index: 2;
  position: relative;
  border-radius: var(--border-radius);
  background: var(--background-color);
  box-shadow: var(--dark-box-shadow-block);

  @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
    box-shadow: none;
    background: none;
    border-radius: 0;

    &&& {
      padding: 0;
    }
  }
`;
