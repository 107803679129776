import ManagementInterface from "@interfaces/ManagementInterface";

const MANAGEMENT: ManagementInterface[] = [
  {
    name: "Mike Duma",
    position: "Founder & CEO",
  },
  {
    name: "Jon Foster",
    position: "Head of Design",
  },
  {
    name: "Trevor Crouch",
    position: "Head of Operations",
  },
];

export default MANAGEMENT;
